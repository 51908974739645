import React, {useRef, useState} from 'react'
import './Authorization.scss'
import axios from 'axios'
import { toast } from 'react-toastify';
import 'react-phone-number-input/style.css'
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input'
import img_checked from '../../assets/checked.png'

function Authorization() {
    const [userPhoneNumber, setPhone] = useState('');
    const [userCode, setCode] = useState('');
    const [status, setStatus] = useState('');

    const phoneInput = useRef(null);
    const codeInput = useRef(null);

    const [userName, setUserName] = useState('');
    const [userAge, setUserAge] = useState('');
    const [userGender, setUserGender] = useState('');
    const [userEmail, setUserEmail] = useState('');

    const api = process.env.REACT_APP_API_URL;

    const sendCode = async (event) => {
        if(userPhoneNumber === undefined || !isValidPhoneNumber(userPhoneNumber)){
            toast.error("Invalid phone number. Please make sure you've entered your data correctly and try again.");
            return;
        }

        event.target.disabled = true;
        phoneInput.current.disabled = true;
        
        const data = {
            user_phone: formatPhoneNumber(userPhoneNumber)
        }

        axios.post(`${api}/user/verification/request`, data).then((response) => {
            const resStatus = response.data.status;
            setStatus(resStatus);
            if(resStatus === 'awaiting'){
                toast.success("Verification code is on it's way! Open your WhatsApp app.");
            }
            else if (resStatus === 'verified'){
                toast.success("This phone number was already verified!")
            }
            event.target.disabled = false;
        }).catch((error) => {
            toast.error(error.response.data);
        });
    }

    const confirmCode = async (e) => {
        e.target.disabled = true;
        codeInput.current.disabled = true;

        const data = {
            user_phone: formatPhoneNumber(userPhoneNumber),
            code: userCode
        }

        axios.post(`${api}/user/verification/confirm`, data).then((response) => {
            toast.success("Verified successfully!");
            const resStatus = response.data.status;
            setStatus(resStatus);
        }).catch((error) => {
            toast.error(error.response.data);
        });
    }

    const handleCodeChange = (e) => {
        setCode(e.target.value);
    }

    const formatPhoneNumber = (phoneNumber) => {
        let result = phoneNumber.replace("+","");
        return result;
    }

    const saveUserInfo = async (e) => {
        e.preventDefault();
        e.target.disabled = true;

        const cleanPhone = userPhoneNumber.replace("+", "");

        const data = {
            user_phone: cleanPhone,
            language: "English",
            name: userName,
            age: userAge,
            gender: userGender,
            timezone: getTimezone(),
            email: userEmail
        }

        axios.post(`${api}/user/setup/confirmation`, data).then((response) => {
            toast.success("User information updated successfuly!");
            const resStatus = response.data.status;
            setStatus(resStatus);
        }).catch((error) => {
            toast.error(error.response.data);
        });
    }

    const getTimezone = () => {
        let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        
        timezone = timezone === "Europe/Kiev" ? "Europe/Kyiv" : timezone;

        return timezone;
    }

    return (
        status !== 'verified' && status !== "done"
        ?(<div className="wrapper">
            <div className="form container">
                {status === 'awaiting'
                    ? (
                        <header>
                            <h3 className='title'>Authorization</h3>
                            <p className='description'>We send you a verification code to your WhatsApp app. Please confirm the code below.</p>
                        </header>
                    )
                    : (
                        <header>
                            <h3 className='title'>Authorization</h3>
                            <p className='description'>Thank you for choosing our product! Before we start, please verify your WhatsApp number.</p>
                        </header>
                    )
                }
                <main>
                    <div className="inputs">
                        <div className="phone input-group">
                            <label htmlFor="userPhoneNumber">WhatsApp Number:</label>
                            <PhoneInput
                                ref={phoneInput}
                                placeholder="Enter phone number"
                                value={userPhoneNumber}
                                onChange={setPhone}
                                id="userPhoneNumber"/>
                        </div>

                        {status === 'awaiting'
                            ? (<div className="confirmation input-group">
                                <label htmlFor="userConfirmation">Confirmation Code:</label>
                                <input type="text" ref={codeInput} name="userConfirmation" id="userConfirmation" placeholder='000000' value={userCode} onChange={handleCodeChange}/>
                            </div>)
                            : (<div></div>)
                        }

                        {
                            status === 'awaiting'
                            ? (<button className='btn' onClick={confirmCode}>Confirm</button>)
                            : (<button className='btn' onClick={sendCode}>Submit</button>)
                        }
                    </div>
                </main>
            </div> 
        </div>)
        : status !== "done" ?
        (<div className='wrapper'>
            <div className="form container">
                <header>
                    <h3 className='title'>Let's finetune your experience</h3>
                    <p className='description'>Before we start, we would like to ask you a couple of questions to personalize your experience.</p>
                </header>
                <main>
                    <form className='inputs'>
                        <div className='name input-group'>
                            <label htmlFor='user_name'>Your name</label>
                            <input type='text' id='user_name' required onChange={(e) => setUserName(e.target.value)}/>
                        </div>
                        <div className='email input-group'>
                            <label htmlFor='user_email'>Your email</label>
                            <input type='email' id='user_email' required onChange={(e) => setUserEmail(e.target.value)}/>
                        </div>
                        <div className='age input-group'>
                            <label htmlFor='user_age'>Your age</label>
                            <input type='number' min={18} id='user_age' required onChange={(e) => setUserAge(e.target.value)}/>
                        </div>
                        <div className='gender input-group'>
                            <label htmlFor='user_gender'>Your gender</label>
                            <select id='user_gender' required onChange={(e) => setUserGender(e.target.value)}>
                                <option value={""} disabled selected>Select Your Gender</option>
                                <option value={"female"}>Female</option>
                                <option value={"male"}>Male</option>
                                <option value={"other"}>Other</option>
                            </select>
                        </div>
                        <button className='btn' onClick={saveUserInfo}>Submit</button>
                    </form>
                </main>
            </div>
        </div>)
        :
        (
            <div className='wrapper'>
                <div className='form container'>
                    <header>
                        <h3 className='title'>Authorization</h3>
                        <p className='description'>Thank you for your patience. You can chat with the chatbot now.</p>
                    </header>
                    <main>
                        <img className='confirmed' src={img_checked} alt=""></img>
                        <p>All done! Click the button bellow and say "Hi" to the chatbot to start your journey.</p>
                        <div className='inputs'>
                            <a className='btn' href={process.env.REACT_APP_WA_CHATBOT_URL}>Go to WhatsApp</a>
                        </div>
                    </main>
                </div>
            </div>
        )
    )
}

export default Authorization